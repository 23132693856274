import * as React from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="beBns6cA42k" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <React.Fragment>
        <p>
          詩人、谷川俊太郎。「鉄腕アトム」のアニソンで、CMのナレーションで、教科書のなかで、私たちは世代を超えて谷川さんの詩に触れてきました。みずみずしい言葉が紡ぎ出す宇宙は、不思議な力で私たちを惹きつけます。“詩は人々の日常と向き合えるか”。本作では、谷川さんの創作の現場から、海、畑、学校、路上、そして神降ろしの場、いろいろな場所で生きる人々を追っていきます。生きる土地も世代もばらばらな人々の、それぞれの苦しみと喜び。彼らは自分の言葉で、ときには谷川さんの詩でそれを伝えようとします。すると詩はさまざまな表情を見せ、被災地で、スイスで、高架下で、詩の言葉はひとり歩きを始め、そして谷川さんに戻り、やがて新しい詩が生まれます。
        </p>
        <div className="links">
          <a href="https://www.amazon.co.jp/dp/B0BMFT8KKR" target="_blank" rel="noreferrer">
            AmazonでDVD販売中！<span>↗</span>
          </a>
        </div>
        <div className="note">
          <small>
            ※学校・図書館・公共施設向けのDVD販売につきましては <Link to="/contact/">CONTACT</Link> からお問い合わせください。
          </small>
        </div>
      </React.Fragment>
    );
  };

  const summaryData = {
    scopes: ['All'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 4)} column={1} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/tanikawa"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/tanikawa.*"}) {
      publicURL
    }
  }
`;
